/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
 * Modular Measure Page.js
 * This redesign of the proposition page will be based
 * around a standardized framework for the page
 * { header, information, action, addendum }
 * that can be modified to suit all the different situations.
 * MVP: works for status: circulating, rBallotStatus: active/inactive, auth: user/guest
 * located at "/measure/:id" for now
*/

import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { getMeasure } from '../../firebase.js';
//import Follow from '../Follow.js';
import { UserContext, PersonContext } from '../../Context.js';
import { dateFromTimestamp, listArrayContents, datetimeFromTimestamp } from '../Utility.js';
import UnverifiedEmail from '../UnverifiedEmail.js';
import './ModularMeasurePage.css';
import '../../Variables.css';
import { Container, Accordion } from 'react-bootstrap';
import { SharingDropdown } from '../SharingLinks.js';
import LikesDislikes from '../LikesDislikes.js';
import { SignButton } from '../Buttons.js';
import CycleComments from '../CycleComments.js';

//for now, will just use ballotpedia title. When revenue needed, must use own
export const Header = (props) => {
    if (!('measure' in props)) return "";
    const measure = props.measure;
    let text = "No title available.";
    /*switch (measure.rBallotStatus) {
        case undefined:
            text = measure.BallotpediaTitle;
            break;
        case "not active":
            text = measure.BallotpediaTitle;
            break;
        case "pending":
            text = measure.BallotpediaTitle;
            break;
        case "signing up":
            text = measure.BallotpediaTitle;
            break;
        case "volunteering":
            text = measure.rBallotTitle;
            break;
        default:
            text = "No relevant title."
    }*/
    if (measure.BallotName) {
        text = measure.BallotName;
    } else if (measure.rBallotTitle) {
        text = measure.rBallotTitle;
    } else if (measure.BallotpediaTitle) {
        text = measure.BallotpediaTitle;
    }
    
    return (
        <div className="PropBox" style={{padding: '1em 1em 0.5em', marginBottom: '0em'}}>
            <h1>
                {text}

                {/* <h1 className="PropBox">{ measure.BallotpediaTitle ? ballotpediaTitle :
                    title ? title :
                    "Title not yet received from Secretary of State." 
                }</h1> */}
            </h1>
            <p style={{color: "darkred", paddingLeft: '1em'}}>
                {/*<strong>{measure.Topic ? measure.Topic.length === 1 ? "Topic: " : "Topics: " : ""}</strong>*/}                    
                {measure.Topic ? listArrayContents(measure.Topic) : ""} 
            </p>
        </div>
    )
}

//now contains propositionsObject and setPropositionsObject
function ModularMeasurePage(props) {

    const userContext = useContext(UserContext);
    const personContext = useContext(PersonContext);

    //the page context will be used by checkDisplay to determine
    //which components to render, and will be an object made up of
    //{ status, rBallotStatus, access }
    const [pageContext, setPageContext] = useState({});
    
    //both of these rely on react-router, and allow
    //us to navigate to other pages of the app without
    //refreshing, and to access an object of key/value pairs
    //or URL parameters. Here, only one parameter is passed (id)
    //note: we must name the parameter the same as the URL parameter (id here)
    const navigate = useNavigate();
    const { id, referrerId } = useParams();

    //an object to hold the information from the database on measure id
    const [measure, setMeasure] = useState(props.propositionsObj ? props.propositionsObj[id] : undefined);
    //a boolean to tell if user is following measure
    //undefined means not loaded, false means guest or auth/notfollowing, true means following
    const [following, setFollowing] = useState(undefined);
    //Was hoping to not need state for this, but this will be passed
    //down to follow button to display a loading animation on click
    const [changingFollow, setChangingFollow] = useState(false);
    //a string to tell if the user has liked or disliked the measure
    //will be undefined for loading, or 'liked', 'disliked', or 'none'
    const [likeValue, setLikeValue] = useState(undefined);

    //for sharing
    //const [shareUrl, setShareUrl] = useState("https://www.rballot.org/measure/" + id);
    const [shareTitle, setShareTitle] = useState("Sign this measure with me!");


//-------------Set sharing information, including head for Open Graph-----------------
    /*useEffect(() => {
        document.title = id;
    }, [id]);*/
    
    useEffect(() => {
        if (!measure) return;

        if (userContext) {
            if ('Likes' in measure && userContext.uid in measure.Likes) {
                setLikeValue('liked');
            } else if ('Dislikes' in measure && userContext.uid in measure.Dislikes) {
                setLikeValue('disliked');
            } else {
                setLikeValue('none');
            }
        }

        //use the available title for the measure
        if (measure.rBallotStatus === "volunteering") {
            setShareTitle("Sign the " + measure.rBallotTitle + " with me!");
        } else {
            setShareTitle("Sign the " + measure.BallotpediaTitle + " with me!");
        }

    }, [measure, userContext]);

//-------------Effect to grab the measure information from db-------------------

    //set up a listener on the petition that will update when anything changes
    //note: should be done with suspense in newer react
    //second note: does this need to be a listener even?
    /*useEffect(() => {    
        const propRef = db.collection("petitions").doc(id);

        const unsubscribe = propRef.onSnapshot(function(doc){
            setMeasure(doc.data());
            console.log("Snapshotted petition " + id + "'s document.");
        })

        return function cleanUp() {
            unsubscribe();
        }

    }, [id]);*/

    useEffect(() => {
        if (!props.propositionsObj || !id) return;

        const grabMeasure = async () => {
            try {
                const measureDownload = await getMeasure(id);
                measureDownload.id = id;
                const newPropsObj = { ...props.propositionsObj };
                newPropsObj[id] = measureDownload;
                props.setPropositionsObj(newPropsObj);
                return;
            } catch (error) {
                console.error(error);
                //setMeasure("Does not exist.");
                return;
            }
        }

        if (props.propositionsObj[id]) {
            setMeasure({ ...props.propositionsObj[id], id: id});
        } else {
            grabMeasure();
        }

    }, [props, props.propositionsObj, id, props.setPropositionsObj]);

//------------Effect to use personContext to tell whether user is following measure--------
//note: now will also use to modify the shareUrl with person's id

    useEffect(() => {
        if (!personContext) return;

        if (personContext.following) {
            if (personContext.following.indexOf(id) !== -1) {
                setFollowing(true);
            } else {
                setFollowing(false);
            }
        } else {
            setFollowing(false);
        }

        //let's just assume this only fires when the following is done
        //seems to be working so far
        setChangingFollow(false);

    }, [personContext, id]);

//----------Effect to create the appropriate context for checkDisplay----------

    useEffect(() => {
        //wait until we have the user and the measure data
        if (userContext === "Not loaded" || !measure) return;

        //this will be called in a situation in which we know that
        //userContext exists (not a guest)
        const checkPermissions = (measureID) => {
            if (userContext.permissions) {
                if (userContext.permissions['c']) {
                    if (userContext.permissions['c'].indexOf(measureID) !== -1) return true;
                }
                if (userContext.permissions['p']) {
                    if (userContext.permissions['p'].indexOf(measureID) !== -1) return true;
                }
                if (userContext.permissions['m']) {
                    if (userContext.permissions['m'].indexOf(measureID) !== -1) return true;
                }
            }
            //user has no permissions associated with this measureID
            return false;
        }

        //we will collapse the multiple possible permissions for a user to just one
        //of following: guest, unverified, emailVerified, permissions
        let access;
        if (userContext) {
            // if they have any access permission to this page
            if (checkPermissions(id)) {
                access = 'permissions';
            // if they have no access to page, but have verified their email
            } else if (userContext.emailVerified) {
                access = 'emailVerified';
            // or if they are a user, but not a verified one
            } else {
                access = 'unverified';
            }
        //undefined userContext means a guest
        } else {
            access = 'guest';
        }

        let newPageContext = {
            status: measure.Status,
            //if there is no rBallotStatus, it must not be active
            rBallotStatus: measure.rBallotStatus ? measure.rBallotStatus : "not active",
            access: access,
            rBallotServices: measure.rBallotServices ? measure.rBallotServices : [], // what has the proponent signed up for
            //options are ["generation", "mailing", "build-a-ballot"]
        }

        //console.log(newPageContext);
        setPageContext(newPageContext);

    }, [userContext, measure, id])

//---------------This returns content specified by the relevant election authority-----------

   function jurisdictionInformation(type, name)
   {
        switch(type) {
            case "State":
                switch(name) {
                    case "California":
                        return (
                            <div style={{'paddingBottom': '0.25em'}}>
                                <a
                                    className="HasTooltip"
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://www.sos.ca.gov/elections/ballot-measures/initiative-and-referendum-status/official-top-funders">
                                    Top Funder Declarations - Check for {id}
                                    <span className="Tooltip">
                                        Click to visit the California Secretary of State's website for a log of the Top Funders disclosure sheets submitted to them
                                    </span>
                                </a>
                            </div>
                        );
                    default:
                        return ""
                }
            case "County":
                break;
            case "City":
                break;
            default:
                break;
        }

        return "";
   }

    //----- Signature Field Stuff --Kayla did this- ----

    /*   function saveSig(signatureImage){
            const sigFieldRef = db.collection("users").doc(userContext.uid);
            sigFieldRef.set(
                {signature: signatureImage},
                {merge: true}
            );
            return;
        }

        async function getSigField(){
            const sigFieldRef = db.collection("users").doc(userContext.uid);
            let userSigField;
            await sigFieldRef.get().then(function(doc)
            {
                if (doc.data().signature) userSigField = doc.data().signature;
            });
            return userSigField;
        }*/

    const arraysContainSameItem = (arr1, arr2) => {
        for (let i = 0; i<arr1.length; i++) {
            for (let j = 0; j<arr2.length; j++) {
                if (arr1[i] === arr2[j]) return true;
            }
        }
        return false;
    }
    
        //------------ Check whether to display something ------------
    // params: context is an object with { status, rBallotStatus, access },
    // where each key has a string as its value, and provides the current
    // metadata of what situation modular measure page is being rendered.
    // settings tells what the current element to be displayed requires
    // and is an object also with { status, rBallotStatus, access } but
    // where each key has a value of an array with multiple possibilities
    const checkDisplay = (context, settings) => {
        /* Below could make this completely adaptable,
        but for now explicit seems clearer
        for (let i = 0; i<context.length; i++) {
            if (settings.indexOf(context))
        }*/
        if (settings.access.indexOf(context.access) !== -1 &&
            settings.rBallotStatus.indexOf(context.rBallotStatus) !== -1 &&
            settings.status.indexOf(context.status) !== -1 &&
            (settings.rBallotServices.includes("any") ||
            arraysContainSameItem(settings.rBallotServices, context.rBallotServices))
            )
        {
            return true;
        } else {
            return false; 
        }

    }

    //---------- Twitter widgets script -------------
    //https://dev.to/heymarkkop/embed-twitter-widget-on-reactjs-1768

    /*useEffect(() => {
        const tweetElement = document.getElementById("TweetButton");
        //if there is no tweet element created yet, don't do anything
        if (!tweetElement) return;
        //or, if the measure or its title haven't loaded yet, return
        if (!measure || !measure.BallotpediaTitle) return;

        //if we've got the element and the title, then we're good to go
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        tweetElement.appendChild(script);
    }, [measure]);*/
    
    //--------- Facebook script --------------
    //https://developers.facebook.com/docs/plugins/share-button/#

    /*useEffect(() => {
        /*let script = document.createElement("script");
        script = (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
            fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk')); */ /*
        const script = document.createElement("scripts");
        script.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
        const facebookShareElement = document.getElementById("fb-share-button");
        if (facebookShareElement) facebookShareElement.appendChild(script);
    }, [])*/

   //-------------------------header, information, actions, addendum----------------------------

    //for now, will just use ballotpedia title. When revenue needed, must use own
    /*const header = (measure) => {
        let text;
        switch (measure.rBallotStatus) {
            case undefined:
                text = measure.BallotpediaTitle;
                break;
            case "not active":
                text = measure.BallotpediaTitle;
                break;
            case "signing up":
                text = measure.BallotpediaTitle;
                break;
            case "volunteering":
                text = measure.rBallotTitle;
                break;
            default:
                text = "No relevant title."
        }
        
        return (
            <div className="PropBox" style={{padding: '1em 1em 0em', marginBottom: '0em'}}>
                <h1>
                    {text}

                    {/* <h1 className="PropBox">{ measure.BallotpediaTitle ? ballotpediaTitle :
                        title ? title :
                        "Title not yet received from Secretary of State." 
                    }</h1>
                </h1>
                <p style={{color: "darkred", paddingLeft: '1em'}}>
                    {/*<strong>{measure.Topic ? measure.Topic.length === 1 ? "Topic: " : "Topics: " : ""}</strong>                    
                    {measure.Topic ? listArrayContents(measure.Topic) : ""} 
                </p>
            </div>
        )
    }*/

    //information relevant to the status of petition
    const information = () => {

        //I am pulling all of below out of actions, but maybe I should just be pulling actions as a whole up
        //and having it live in the sidebar, and have the sidebar stay on screen when scrolling the other one?

        return (
            <div className="InformationSection" style={{display:'flex'}}>
                {/*<div style={{borderRadius: '15px', overflow: 'hidden'}}>*/}
                <Accordion style={{width: '100%'}} defaultActiveKey={["rBallotOneLiner", "rBallotSummary", "rBallotYesAndNo", "rBallotProAndCon", "Title", "Summary", "FiscalImpact"]} className="PropBox" flush alwaysOpen>
                    {/* !measure.rBallotSummary ? "" :
                        <Accordion.Item eventKey="rBallotSummary" className="MeasureInfoAccordionItem">
                            <Accordion.Header>rBallot Summary (written by AI)</Accordion.Header>
                            <Accordion.Body>{ measure.rBallotSummary }</Accordion.Body>
                        </Accordion.Item>*/
                    } { !measure.rBallotSummary ? "" :
                        <Accordion.Item eventKey="rBallotSummary" className="MeasureInfoAccordionItem">
                            <Accordion.Header>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <span className="MeasureInfoAccordionHeader" style={{width: '100%'}}>Summary</span>
                                    <span style={{paddingTop: '0.5em', fontSize: '0.9em', color: 'gray'}}>AI-generated from the text of the measure</span>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>{ measure.rBallotSummary ? measure.rBallotSummary : "No AI summary exists." }</Accordion.Body>
                        </Accordion.Item>
                        } { !measure.rBallotProAndCon ? "" :
                        <Accordion.Item eventKey="rBallotProAndCon" className="MeasureInfoAccordionItem">
                            <Accordion.Header>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <span className="MeasureInfoAccordionHeader" style={{width: '100%'}}>Pro and Con Arguments</span>
                                    <span style={{paddingTop: '0.5em', fontSize: '0.9em', color: 'gray'}}>AI-generated from the text of the measure</span>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>{ measure.rBallotProAndCon ? measure.rBallotProAndCon : "No AI pro and con info exists." }</Accordion.Body>
                        </Accordion.Item>
                    } { !measure.rBallotYesAndNo ? "" :
                        <Accordion.Item eventKey="rBallotYesAndNo" className="MeasureInfoAccordionItem">
                            <Accordion.Header>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <span className="MeasureInfoAccordionHeader" style={{width: '100%'}}>Yes And No Explanation</span>
                                    <span style={{paddingTop: '0.5em', fontSize: '0.9em', color: 'gray'}}>AI-generated from the text of the measure</span>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>{ measure.rBallotYesAndNo ? measure.rBallotYesAndNo : "No AI yes and no description exists." }</Accordion.Body>
                        </Accordion.Item>
                    }  { !measure.rBallotOneLiner ? "" :
                        <Accordion.Item eventKey="rBallotOneLiner" className="MeasureInfoAccordionItem">
                            <Accordion.Header>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <span className="MeasureInfoAccordionHeader" style={{width: '100%'}}>Description</span>
                                    <span style={{paddingTop: '0.5em', fontSize: '0.9em', color: 'gray'}}>AI-generated from the text of the measure</span>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>{ measure.rBallotOneLiner ? measure.rBallotOneLiner : "No AI one-liner exists." }</Accordion.Body>
                        </Accordion.Item>
                    } 
                    {!measure.Title ? "" : 
                    <Accordion.Item eventKey="Title" className="MeasureInfoAccordionItem">
                        <Accordion.Header><span className="MeasureInfoAccordionHeader">Official Title</span></Accordion.Header>
                        <Accordion.Body>{ measure.Title ? measure.Title : "Title not yet received from Secretary of State."}</Accordion.Body>
                    </Accordion.Item>}
                    { !measure.Summary ? "" : 
                    <Accordion.Item eventKey="Summary" className="MeasureInfoAccordionItem">
                        <Accordion.Header><span className="MeasureInfoAccordionHeader">Official Summary</span></Accordion.Header>
                        <Accordion.Body>{ measure.Summary ? measure.Summary : "Circulating summary not yet received from Secretary of State."}</Accordion.Body>
                    </Accordion.Item>}
                    { !measure.FiscalImpact ? "" : 
                    <Accordion.Item eventKey="FiscalImpact" className="MeasureInfoAccordionItem">
                        <Accordion.Header><span className="MeasureInfoAccordionHeader">Fiscal Impact</span></Accordion.Header>
                        <Accordion.Body>{ measure.FiscalImpact ? measure.FiscalImpact : "Fiscal impact not yet received from Secretary of State."}</Accordion.Body>
                    </Accordion.Item>}
                </Accordion>
                {/*</div>*/}

                <div className="MeasureSidebar PropBox" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '30%'}}>
                    <div style={{display: 'flex', flexDirection: 'column', padding: '0.8em 0.5em', width: '100%'}}>
                        {!measure.Website ? "" : 
                            <p style={{textAlign: 'right', paddingBottom: '0.5em', borderBottom: '1px solid lightgray'}}>
                                <span style={{padding: '0 0.5em 0.25em 0', fontWeight: 'bold', float:'left', maxWidth: '10em', textAlign: 'left'}}>Campaign Website</span>
                                <a target="_blank" rel="noreferrer" href={measure.Website}>{measure.Website}</a>
                            </p>
                        }
                        <p style={{textAlign: 'right', paddingBottom: '0.5em', borderBottom: '1px solid lightgray'}}>
                            <span style={{padding: '0 0.5em 0.25em 0', fontWeight: 'bold', float:'left', maxWidth: '10em', textAlign: 'left'}}>Status</span>
                            {measure.Status ? measure.Status : "Circulating status unknown."}
                        </p>
                        { !measure.JurisdictionType || !measure.JurisdictionName ? "" :
                        <p style={{textAlign: 'right', paddingBottom: '0.5em', borderBottom: '1px solid lightgray'}}>
                            <span style={{padding: '0 0.5em 0.25em 0', fontWeight: 'bold', float:'left', maxWidth: '10em', textAlign: 'left'}}>{measure.JurisdictionType}</span>
                        { measure.JurisdictionName }
                        </p>
                        }
                        { !measure.FullTextLink ? "" :
                        <p style={{textAlign: 'right', paddingBottom: '0.5em', borderBottom: '1px solid lightgray'}}>
                            <span style={{padding: '0 0.5em 0.25em 0', fontWeight: 'bold', float:'left', maxWidth: '10em', textAlign: 'left'}}>Full Text</span>
                            <a target="_blank" rel="noreferrer" href={measure.FullTextLink}>Full text of measure</a>
                        </p>
                        }
                        { !measure.ImpartialAnalysisLink ? "" :
                        <p style={{textAlign: 'right', paddingBottom: '0.5em', borderBottom: '1px solid lightgray'}}>
                        <span style={{padding: '0 0.5em 0.25em 0', fontWeight: 'bold', float:'left', maxWidth: '10em', textAlign: 'left'}}>Impartial analysis</span>
                        <a target="_blank" rel="noreferrer" href={measure.ImpartialAnalysisLink}>Impartial analysis of measure</a>
                        </p>
                        }
                        { measure.Status === 'Cleared for signature gathering' || measure.Status === '25 percent of signatures reached' ?
                        <p style={{textAlign: 'right', paddingBottom: '0.5em', borderBottom: '1px solid lightgray'}}>
                            <span style={{padding: '0 0.5em 0.25em 0', fontWeight: 'bold', float:'left', maxWidth: '10em', textAlign: 'left'}}>Deadline</span>
                            { dateFromTimestamp(measure.Deadline) }
                        </p> 
                        :
                        //measure.Status === 'Qualified for the ballot' || measure.Status === 'On the ballot' ?
                        <p style={{textAlign: 'right', paddingBottom: '0.5em', borderBottom: '1px solid lightgray'}}>
                            <span style={{padding: '0 0.5em 0.25em 0', fontWeight: 'bold', float:'left', maxWidth: '10em', textAlign: 'left'}}>Election Date</span>
                            { measure.ElectionDate ? measure.ElectionDate : "Unavailable" }
                        </p>
                        //:
                        //"" // no deadline or election date
                        }
                        {/*<p style={{textAlign: 'right', paddingBottom: '0.5em', borderBottom: '1px solid lightgray'}}>
                            <span style={{padding: '0 0.5em 0.25em 0', fontWeight: 'bold', float:'left', maxWidth: '10em', textAlign: 'left'}}>Signatures</span>
                            { !measure.SignaturesObj ? "0" : 
                            ((measure.SignaturesObj.total || 0)+" + "+(measure.SignaturesObj.totalProspective+" in progress" || 0))
                            }
                        </p>*/}
                        { measure.Status !== 'Cleared for signature gathering' && measure.Status !== '25 percent of signatures reached' ? "" :
                        <p style={{textAlign: 'right', paddingBottom: '0.5em', borderBottom: '1px solid lightgray'}}>
                            <span style={{padding: '0 0.5em 0.25em 0', fontWeight: 'bold', float:'left', maxWidth: '10em', textAlign: 'left'}}>Signatures Required</span>
                            {measure.SignaturesReq ? measure.SignaturesReq.toLocaleString() : "1,000,000"}
                        </p>
                        }
                        { !measure.Proponents ? "" :
                        <p style={{textAlign: 'right', paddingBottom: '0.5em', borderBottom: '1px solid lightgray'}}>
                            <span style={{padding: '0 0.5em 0.25em 0', fontWeight: 'bold', float:'left'}}>Proponents</span>
                            {!measure.Proponents ? "" : 
                            typeof measure.Proponents === "string" ? measure.Proponents : listArrayContents(measure.Proponents)} 
                            { measure.Campaign ? (" with " + measure.Campaign) : ""}
                        </p>
                        }
                    </div>
                    <LikesDislikes 
                        //propositionsObj={props.propositionsObj}
                        setPropositionsObj={props.setPropositionsObj}
                        //likeValue={likeValue}
                        //setLikeValue={setLikeValue}
                        measure={measure}
                    />
                    {/* This div should become a class so that it can change based on window */}
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                        <SharingDropdown 
                            size={24} 
                            measureID={measure.id} 
                            shareTitle={measure.rBallotTitle ? ("Sign the " + measure.rBallotTitle + " with me!") : ("Sign the " + measure.BallotpediaTitle + " with me!")} 
                            round={false}
                        />
                        { measure.Status !== 'Cleared for signature gathering' && measure.Status !== '25 percent of signatures reached' ? "" :
                            <SignButton 
                                measure={measure}
                                referrerId={referrerId}
                            />
                        }
                    </div>
                    { // This section will cycle through comments on the measure one at a time
                    <CycleComments measure={measure} />
                    }
                </div>

                
                {/*<div className="PropBox">
                    { !measure.Website ? "" :
                        <p><strong>Campaign Website: </strong><a target="_blank" rel="noreferrer" href={measure.Website}>{measure.Website}</a></p>
                    }
                    <p> { "Proposed by " } { !measure.Proponents ? "" : 
                        typeof measure.Proponents === "string" ? measure.Proponents : listArrayContents(measure.Proponents)} 
                        { measure.Campaign ? (" with " + measure.Campaign) : ""}
                    </p>
                    {measure.FullTextLink ? 
                    <p><a target="_blank" rel="noreferrer" href={measure.FullTextLink}>Full text of measure</a></p>
                    :
                    ""
                    }
                    {/* below is an example of something that is not required for volunteer drives,
                    but would be required for paid signature drives at a bare minimum.*/ /*
                    jurisdictionInformation(measure.JurisdictionType, measure.JurisdictionName)}
                </div>

                <div className="PropBox">
                    {measure.rBallotStatus === "volunteering" && measure.rBallotServices && measure.rBallotServices.includes("mailing") ?
                        <p><strong>Signatures: </strong>{
                            !measure.SignaturesObj ? "0" : /*measure.requestedCount*//*((measure.SignaturesObj.total || 0)+" + "+(measure.SignaturesObj.totalProspective+" in progress" || 0))}</p>
                        :
                        ""
                    }
                    {measure.rBallotStatus === "volunteering" && measure.rBallotServices.includes("generation") ? 
                        <p><strong>Volunteers: </strong>{measure.CirculatorCount || 0}</p> 
                        : 
                        ""
                    }
                    <p><strong>Followers: </strong>{ measure.Followers ? measure.Followers : 0 }</p>
                    <p><strong>Deadline: </strong>{ dateFromTimestamp(measure.Deadline) } </p>
                    <p><strong>Signatures Required: </strong>{measure.SignaturesReq ? measure.SignaturesReq : "1,000,000"}</p>
                    <p>
                    <strong>Status: </strong>{measure.Status ? measure.Status : "Circulating status unknown."}
                    </p>
                </div>
                { /* 
                <div className="PropositionEditor">
                    <ReadOnlyDraftEditor contentState={text}/>
                </div>
                */ }

                {/**  Link to text */}

                {!measure.PhoneNumber || !measure.PhoneNumberPublic ? "" : 
                    <p className="PropBox">{"Campaign Phone Number: "}{measure.PhoneNumber}</p>
                }
                {!measure.Email || !measure.EmailPublic ? "" : 
                    <p className="PropBox">{"Campaign Email: "}{measure.Email}</p>
                }
            </div>
        )
    }

//----------------------Modular action boxes below------------------------------
//for each, props  will contain information related to the current measure.
//and possibly, functions to modify state on this page from within component.

    //possible actions, including circulating, following, etc.
    //change based on user, petition status, and proponent status
    const actions = () => {
        
        //settings for different actions
        const followSettings = {
            status: ['Cleared for signature gathering', '25 percent of signatures reached'],
            rBallotStatus: ['not active', 'signed up', 'volunteering', 'paid'],
            //we should only be able to follow if signed up (with email verified),
            //but guests should also render follow, which will instead present
            //a sign up link that will also lead to following afterwards
            access: ['guest', 'emailVerified', 'permissions'],
            rBallotServices: ['any']
        };

        const signSettings = {
            status: ['Cleared for signature gathering', '25 percent of signatures reached'],
            rBallotStatus: ['not active', 'volunteering', 'paid'],
            access: ['guest', 'emailVerified', 'permissions'],
            rBallotServices: ['any']
        };

        const organizationSettings = {
            status: ['Cleared for signature gathering', '25 percent of signatures reached'],
            rBallotStatus: ['not active', 'volunteering', 'paid'],
            access: ['guest', 'emailVerified', 'permissions'],
            rBallotServices: ['generation']
        };

        const verifyEmailSettings = {
            status: ['Cleared for signature gathering', '25 percent of signatures reached'],
            rBallotStatus: ['not active', 'signed up', 'volunteering', 'paid'],
            access: ['unverified'],
            rBallotServices: ['any']
        };

        const volunteerSettings = {
            status: ['Cleared for signature gathering', '25 percent of signatures reached'],
            rBallotStatus: ['volunteering'],
            //volunteer button will also initiate sign up process for a guest
            access: ['guest', 'emailVerified'],
            rBallotServices: ['generation']
        };

        /*const shareLinkSettings = {
            status: ['Cleared for signature gathering', '25 percent of signatures reached'],
            rBallotStatus: ['not active', 'signed up', 'volunteering', 'paid'],
            access: ['unverified', 'emailVerified', 'permissions'],
            rBallotServices: ['any']
        };*/
        
        return(
            <div className="Actions">
                { /* Redirects to signing page 
                checkDisplay(pageContext, signSettings) ?
                    <div className="MainAction">
                        <Button
                            className="SignButton"
                            onClick={() => {
                                //does this make sense? If not referredID OR there is a userContext?
                                if (!referrerId || userContext) navigate("/measure/"+ id + "/sign");
                                //if a referredId is in the params, user clicked a link from another user
                                if (referrerId) navigate("/emailsignup/referredby/"+referrerId+"/"+id);
                            }}>
                            Sign
                        </Button>
                    </div>
                    :
                    ""    
                        */}
                
                {/*<SharingLinks 
                    size={52}
                    //shareUrl={shareUrl}
                    shareTitle={shareTitle}
                    spacing={'1em'}
                    margin={'1em 0em 0em'}
                    measureID={id}
                />*/}

                <div className="MinorActions">
                    {/* Only display follow button if the context in which 
                    the page is being viewed is that which has been specified
                    in followSettings 
                    checkDisplay(pageContext, followSettings) ?
                        <div className="MainAction">
                            <Follow 
                                user={userContext} 
                                id={id} 
                                measure={measure}
                                following={following}
                                changed={changingFollow}
                                setChanged={setChangingFollow}
                                setMeasure={(followers) => {
                                    //setMeasure({ ...measure, Followers: followers});
                                    const newPropsObj = { ...props.propositionsObj };
                                    newPropsObj[id] = { ...measure, Followers: followers };
                                    props.setPropositionsObj(newPropsObj);
                                }}
                                buttonClass={"FollowButton"}
                            />
                        </div>
                        :
                        ""
                    */}

                    {/** Here we display the button to copy the share link*/
                    /*checkDisplay(pageContext, shareLinkSettings) ?
                        <CopyLink 
                            className="FollowButton"
                            link={"www.rballot.org/measure/" + id + "/" + (userContext ? userContext.uid : "")}
                            text={"Referral Link"}
                        />
                        :
                        ""
                    */}

                    {/* Here we display the circulate button for guests (to trigger sign up)
                    and for those with email verified 
                    checkDisplay(pageContext, volunteerSettings) ?
                        <div className="MainAction">
                            <Button
                                className="FollowButton"
                                onClick={() => navigate( (userContext ? "" : '/emailsignup')
                                                    + "/circulatorverification/"
                                                    + id + "/"
                                                    + measure.JurisdictionName)}
                            >
                                Volunteer
                            </Button>
                        </div>
                        :
                        ""
                    */}

                    { /* Links to organization page for those with permissions 
                    //should this be a button or a link?
                    //note: now this also allows guests and not active measures to work
                    checkDisplay(pageContext, organizationSettings) ?
                        <div className="MainAction">
                            <Button
                                className="FollowButton"
                                onClick={() => navigate("/measure/"
                                + id + "/"
                                + "campaign")}>
                                Circulate
                            </Button>
                        </div>
                        :
                        ""    
                    */}
                </div>

                { /* Tell users with unverified email to verify it UNTESTED */
                checkDisplay(pageContext, verifyEmailSettings) ?
                    <div className="MainAction" style={{textAlign: 'center'}}>
                        <UnverifiedEmail />
                    </div>
                    :
                    ""
                }

                {/*<div className="ShareButtons">
                    <a 
                        id="TweetButton"
                        href="https://twitter.com/intent/tweet"
                        target="_blank"
                        rel="noreferrer"
                        className="twitter-share-button" 
                        data-show-count="true"
                        data-text={measure.BallotpediaTitle}
                        data-url={"www.rballot.org/measure/" + id}
                        data-size="large"
                    >
                    Tweet
                    </a> }
                    {/* Load Facebook SDK for JavaScript? */}
                    {/*<div id="fb-root"></div> */}
                    {/* -- Your share button code -- */}
                    { /* <div 
                        className="fb-share-button" 
                        data-href="https://www.your-domain.com/your-page.html" 
                        data-layout="button_count">
                    </div>
                </div> */}

            </div>
        )
    }        

    const addendum = (        
        <div>
            {
            !measure ? "Loading..." : 
                <div className="Addendum">
                    {/*<Link 
                        to={userContext ?
                            "/ProponentVerification/" + id + "/" + measure.JurisdictionName
                            :
                            "/EmailSignUp/ProponentVerification/" + id + "/" + measure.JurisdictionName
                        }
                    >
                        Are you the proponent of this measure?
                    </Link>
                    <br />
                    <br />*/}
                    { //rBallotCoordination will track whether the measure has coordinated with rBallot to put its signature capability up there
                    !measure.rBallotStatus || measure.rBallotStatus === "not active" || !measure.rBallotCoordination || measure.rBallotStatus === "signing up" ?
                        <p className="LegalDisclaimer">
                            All information shown was compiled from publicly accessible sources including the websites
                            of the California Secretary of State and the nonprofit organization Ballotpedia. All information
                            is provided "as is" with no guarantee of correctness or fitness for a particular purpose.
                        </p>
                        :
                        <p className="LegalDisclaimer">
                            All information shown was provided by the proponent(s) and their agents in charge of signature gathering
                            for this measure's petition drive, or compiled from publicly accessible sources including the websites
                            of the California Secretary of State and the nonprofit organization Ballotpedia. All information
                            is provided "as is" with no guarantee of correctness or fitness for a particular purpose.
                        </p>
                    }
                </div>
            }
        </div>
    );

    return (
        <Container className="MeasureContainer">
            <br />
            <br />
            <div className="MeasurePageBackground"></div>
            {/*<div className="Background2"></div>*/}

            <div className="PropositionInfoContainer">
                {measure ? <Header measure={measure} /> : "Loading..."}
                {measure ? information() : "Loading..."}
                {measure ? actions() : "Loading..."}
                {measure ? addendum : "Loading..."}
            </div>
        </Container>
    )

}

export default ModularMeasurePage;