/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';  //firestore must be included separately
import 'firebase/compat/auth';
//import * as firebaseui from 'firebaseui';
//import 'firebase/performance';

import { 
  getAuth,
  sendEmailVerification,
  createUserWithEmailAndPassword,
  updateProfile,
  RecaptchaVerifier,
  PhoneAuthProvider,
  updatePhoneNumber,
  updateEmail,
  applyActionCode,
  checkActionCode
} from 'firebase/auth';

import { 
  getFirestore, 
  addDoc, 
  collection, 
  getDoc,
  getDocs, 
  updateDoc,
  deleteField,
  doc,
  onSnapshot,
  query,
  orderBy,
  limit,
  where,
  writeBatch,
  setDoc,
  arrayUnion,
  arrayRemove,
  increment,
  or,
  and
} from 'firebase/firestore';

import {
  getStorage,
  ref,
  uploadBytes,
  getBlob/*,
  getMetadata*/
} from 'firebase/storage';

//here is our specific configuration info
const firebaseConfig = {
  apiKey: "AIzaSyBv5FB39OvgN_GOYLDoGc-pZ4PRfpzMshI",
  authDomain: "propcreator-699af.firebaseapp.com",
  databaseURL: "https://propcreator-699af.firebaseio.com",
  projectId: "propcreator-699af",
  storageBucket: "propcreator-699af.appspot.com",
  messagingSenderId: "113949757074",
  appId: "1:113949757074:web:4bd53a55e8875afb19e04b",
  measurementId: "G-E8SE9M1QQD"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

//Enable analytics
//note: do we need this even? meh
//firebase.analytics();

//note for below 3: do I need to put export at the start of each line below?
//perf will be our reference to the Firebase performance monitoring service
//export const perf = firebase.performance();

//db will be our reference to the database
export const db = firebase.firestore();
//goal is to remove references to this. Currently referenced in:
//App.js
//ModularMeasurePage.js REMOVED
//User.js REMOVED
//OrganizationPage.js (one reference) REMOVED DEPRECATED? still an example
//EditMeasure.js (one reference) DEPRECATED? still an example (but also not supported due to date issues)
//ProponentVerification.js DEPRECATED?
//CirculatorVerification.js DEPRECATED?

// auth is our reference to firebase authenticate
export const auth = firebase.auth();
// auth used in
// App.js
// ActionHandler.js

//so that we don't have to import firestore fully everywhere
//we need to get a timestamp
//note: need to update these to v9
export function timestamp()
{
  return firebase.firestore.FieldValue.serverTimestamp();
}
export function now()
{
  return firebase.firestore.Timestamp.now();
}
export function timestampFromDate(date)
{
  return firebase.firestore.Timestamp.fromDate(date);
}

/**
 * getTimestampFromJsonDate
 * Turns a json date into a Firestore timestamp
 * @param {string} json_date 
 * @returns {timestamp}
 */
 export function getTimestampFromJsonDate(json_date) {
  //Reorder it
  const [year, month, day] = json_date.split("-");
  //January is 0
  const date = new Date(year, month-1, day);

  const timestamp = timestampFromDate(date);

  return timestamp;
}

/**
 * getMeasuresFromPersonInfo
 * This will take in the person info and, for now, fetch the measures for the state level for this person
 * @param {} redirectLink 
 */
export async function getMeasuresFromPersonInfo(personInfo, setPropositionsObj) 
{
  if (!personInfo || !setPropositionsObj || !personInfo.State) return;
  const db = getFirestore();
  const measuresRef = collection(db, "measures");
  const currentTime = now();
  // one will fetch circulating measures based on deadline, while the other will fetch measures on the ballot by status name
  const circulatingQuery = query(measuresRef, where("JurisdictionName", "==", personInfo.State), where("Deadline", ">", currentTime));
  const ballotQuery = query(measuresRef, and(
    or(
      where("JurisdictionName", "==", personInfo.State || ""), 
      where("JurisdictionName", "==", personInfo.County || ""), // this makes Santa Cruz City measures show up in Santa Cruz County
      where("JurisdictionName", "==", personInfo.City || ""),
      where("JurisdictionName", "==", personInfo.District || ""),
    ), 
    where("Status", "in", ["On the ballot", "Qualified for the ballot", "Signatures submitted"]),
    where("ElectionTimestamp", ">", currentTime)
  ));
  try {
    const snapshots = await Promise.all([getDocs(circulatingQuery), getDocs(ballotQuery)]);
    const measures = {}
    // set the ordering for the circulating ones based on time left
    const ordering = [];
    // those on the ballot go first
    for (let j = 0; j<snapshots[1].docs.length; j++){
      const doc = snapshots[1].docs[j];
      ordering.push(doc.id);
      const data = doc.data();
      data.id = doc.id;
      measures[doc.id] = data;
    }
    for (let i = 0; i<snapshots[0].docs.length; i++){
      const doc = snapshots[0].docs[i];
      ordering.push(doc.id);
      //measures.push(snapshots[0].doc[i]);
      const data = doc.data();
      data.id = doc.id;
      measures[doc.id] = data;
    }
    setPropositionsObj({
      ordering: ordering,
      ...measures
    });
    console.log("Updated propositions obj!");
  } catch (error) {
    console.error(error);
  }
}
/**
 * putting here so that we can call for email verifications
 * from multiple components/pages
 * notes: this provides instruction to firebase on how to construct the email link
 * that it sends to the user's email
 * https://firebase.google.com/docs/auth/web/email-link-auth#web-version-8
 * I haven't written anything for fields: android, ios, and dynamicLinkDomain
 * 8/7/23: I removed the user parameter, as I had changed it to a custom userContext
 * that no longer worked as it wasn't the firebase one. Rather than undoing that,
 * I am now just getting the current user and sending it to them.
 * thought: a parameter we could pass in is the page to take them to. If, for instance,
 * it is clear that the user is signing up to circulate a specific measure.
 * @param {string} redirectLink
 */
export async function sendFirebaseEmailVerification(redirectLink)
{
  if (!redirectLink) redirectLink = "";

  const actionCodeSettings = {
      //url here might need to dynamically have user ID added on as a parameter
      //example from firebase is url: 'https://www.example.com/cart?email=user@example.com&cartId=123',
      url: ('https://www.rballot.org/' + redirectLink)
  }

  const auth = getAuth();
  const user = auth.currentUser;

  //send a verification email to the user's email
  //that will redirect them to the url in actionCodeSettings
  await sendEmailVerification(user, actionCodeSettings)
      .then(() => console.log("Verification email sent."))
      .catch((error) => console.log(error));
}

/**
 * updateFirebaseEmail
 * Call this to update the user's email to something new
 * @param {string} email
 */
export async function updateFirebaseEmail(email) {
  const auth = getAuth();
  const result = await updateEmail(auth.currentUser, email)
    .catch((error) => {
      console.error(error);
      throw new Error(error);
    });

  return result;
}

/**
 * getMeasure
 * @param measureID
 * retrieves the specified measure and returns the data
 */
export async function getMeasure(measureID)
{
  const db = getFirestore();
  const measureDocRef = doc(db, 'measures', measureID);
  const measureSnap = await getDoc(measureDocRef);

  if (measureSnap.exists()) {
    return measureSnap.data();
  } else {
    throw new Error("No measure with this id exists.");
  }
}

/**
 * getUser
 * @param userID
 * retrieves the public info of the specified user
 */
export async function getUser(userID)
{
  const db = getFirestore();
  const userDocRef = doc(db, 'users', userID);
  const userSnap = await getDoc(userDocRef);

  if (userSnap.exists()) {
    return userSnap.data();
  } else {
    throw new Error("No user with this id exists.");
  }
}

/**
 * getIndexing
 * retrieves the most recent indexing document
 */
export async function getIndexing()
{
  const db = getFirestore();
  const indexingRef = collection(db, 'indexing');
  const indexingQuery = query(indexingRef, orderBy('timestamp'), limit(1));
  
  try {
    const indexingSnapshot = await getDocs(indexingQuery);
    // should be 1 based on limit above, or 0 if none present
    if (indexingSnapshot.docs.length === 1) {
      return indexingSnapshot.docs[0].data();
    } else {
      return undefined;
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * grabVoteInfo
 * This function will be used to get the reaction and reply data for a user's vote
 * (like/dislike) on a measure
 * @param {*} measureId 
 * @param {*} authorId 
 */
export async function getVoteInfo(measureId, authorId)
{
  const db = getFirestore();
  const voteRef = doc(db, 'measures/'+measureId+'/votes/'+authorId);
  try {
    const vote = await getDoc(voteRef);
    if (vote.exists()){
      //console.log(measureId, authorId, vote.data());
      return vote.data();
    } else {
      return undefined;
    }
  } catch(error) {
    console.error(error);
  }
}

/**
 * reactToVote
 * This function will enable users to log reactions to other users' votes (likes/dislikes)
 * on measures.
 */
export async function reactToVote(prevReaction, newReaction, measureId, authorId)
{
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) return;

  console.log(prevReaction, newReaction, measureId, authorId);

  const reactionToKey = (reaction) => {
    switch (reaction) {
      case 'Like':
        return 'Likes'
      case 'Love':
        return 'Loves'
      case 'Insight':
        return 'Insights'
      case 'Question':
        return 'Questions'
      default:
        throw new Error("Reaction not supported.");
    }
  }

  // vote doc lives in the votes subcollection of the measure document, where
  // the vote id is the id of the user who submitted the vote
  const voteDoc = doc(db, 'measures/'+measureId+'/votes/'+authorId);
  // create an object to fill with the changes
  const updateData = {}
  // if there is a newReaction, add the user's id to the corresponding list
  if (newReaction && newReaction!==""){
    updateData[reactionToKey(newReaction)] = arrayUnion(user.uid);
  }
  // if there was a prevReaction, remove it
  if (prevReaction && prevReaction !== "") {
    updateData[reactionToKey(prevReaction)] = arrayRemove(user.uid);
  }

  try {
    await setDoc(voteDoc, updateData, {merge:true});
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
}
/**
 * This function will allow users to comment on someone else's vote
 * to like/dislike a measure,
 * @param {string} measureId 
 * @param {string} authorId 
 * @param {string} comment
 */
export async function commentOnVote(measureId, authorId, comment)
{
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) return;

  // get the reference to the vote in the database
  const voteDoc = doc(db, 'measures/'+measureId+'/votes/'+authorId);
  try {
    // here add the new comment in to this user's comments
    await setDoc(voteDoc, {
      // I hope this doesn't overwrite everything?
      'Replies': {
        [user.uid]: arrayUnion({
          comment: comment,
          timestamp: now(), // cannot do a serverTimestamp in an arrayUnion
          displayName: user.displayName
        })  
      }
    }, {merge:true});
  } catch(error) {
    console.error(error);
  }
}

/**
 * This function will allow a user to delete their own comment on someone's vote
 * @param {string} measureId 
 * @param {string} authorId 
 * @param {Object} commentToDelete 
 */
export async function deleteCommentOnVote(measureId, authorId, commentToDelete)
{
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) return;
  
  // get the reference to the vote in the database
  const voteDoc = doc(db, 'measures/'+measureId+'/votes/'+authorId);
  try {
    // here add the new comment in to this user's comments
    await setDoc(voteDoc, {
      // I hope this doesn't overwrite everything?
      'Replies': {
        [user.uid]: arrayRemove(commentToDelete)  
      }
    }, {merge:true});
  } catch(error) {
    console.error(error);
  }
}

/**
 * vote
 * This function is for liking/disliking a measure, not a comment or post.
 * It may be changed later to 'vote' which will be the term for these.
 * @param {string} status is the current reaction status for this field/document: 'liked', 'disliked', or 'none'
 * @param {string} action is whether we are liking or disliking, or removing one of the above
 * 'like', 'dislike', 'removeLike', 'removeDislike'
 * @param {string} measureId is the measure where this vote lives
 * @param {string} field is the name of the field for this set of likes 
*/
export async function vote(status, action, measureId, field, likeObject)
{
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;
  //some actions will require up to four operations
  //note: I could just do these in one single operation as well... this would mean less writes,
  //though by using two I may be able to make the security rules work better (or at all)
  //const batch = writeBatch(db);

  const newLikeObject = {
    ...likeObject,
    timestamp: timestamp()
  }

  //note the suffixes: [field] + 'Likes' or 'Dislikes'
  try {
    // if we are liking
    if (action === 'like') { 
      // if the user dislikes this, remove dislike before liking
      if (status === 'disliked') {
        await updateDoc(doc(db, 'measures', measureId), {
          [field + 'Dislikes.' + user.uid]: deleteField()
        }, { merge : true });
        await updateDoc(doc(db, 'users', user.uid), {
          [field + 'Dislikes.' + measureId]: deleteField()
        }, { merge: true });
      }
      // here actually apply the like
      await updateDoc(doc(db, 'measures', measureId), {
        [field + 'Likes.' + user.uid]: { ...newLikeObject, name: user.displayName }
      }, { merge : true });
      await updateDoc(doc(db, 'users', user.uid), {
        [field + 'Likes.' + measureId]: { ...newLikeObject }
      }, { merge: true });
    //if we are disliking
    } else if (action === 'dislike') {
      // if the user likes this, remove like before disliking
      if (status === 'liked') {
        await updateDoc(doc(db, 'measures', measureId), {
          [field + 'Likes.' + user.uid]: deleteField()
        }, { merge : true });
        await updateDoc(doc(db, 'users', user.uid), {
          [field + 'Likes.' + measureId]: deleteField()
        }, { merge: true });
      }
      // here actually dislike and log to user
      await updateDoc(doc(db, 'measures', measureId), {
        [field + 'Dislikes.' + user.uid]: { ...newLikeObject, name: user.displayName }
      }, { merge : true });
      await updateDoc(doc(db, 'users', user.uid), {
        [field + 'Dislikes.' + measureId]: { ...newLikeObject }
      }, { merge: true });
    // if we are removing a like
    } else if (action === 'removeLike') {
      await updateDoc(doc(db, 'measures', measureId), {
        [field + 'Likes.' + user.uid]: deleteField()
      }, { merge : true });
      await updateDoc(doc(db, 'users', user.uid), {
        [field + 'Likes.' + measureId]: deleteField()
      }, { merge: true });
    // if we are removing a dislike
    } else if (action === 'removeDislike') {
      await updateDoc(doc(db, 'measures', measureId), {
        [field + 'Dislikes.' + user.uid]: deleteField()
      }, { merge : true });
      await updateDoc(doc(db, 'users', user.uid), {
        [field + 'Dislikes.' + measureId]: deleteField()
      }, { merge: true });
    }
    //await batch.commit();

    //need to prepare the vote document so that it can be reacted to
    //include all the possible information here, since no reason not to, and may transition to fully using this document anyway
    const voteData = {
      ...newLikeObject, //contains timestamp
      type: action, //like, dislike, removeLike, removeDislike, but last two won't do anything
      authorId: user.uid,
      authorName: user.displayName,
      Likes: [],
      Loves: [],
      Insights: [],
      Questions: [],
    };
    //path to the user's vote document
    const voteDoc = doc(db, 'measures', measureId, 'votes', user.uid);
    //don't mind removing the document, just overwrite it
    //though: note that it would be cool to preserve the previous state of the document,
    //so that looking at it you could tell when someone had changed their vote
    if (action === 'like' || action === 'dislike') {
      await setDoc(voteDoc, voteData, {merge:true});
    }

  } catch (error) {
    throw new Error(error);
  }
}

/**
 * allows admin users to update fields on a measure
 * note: I could just make this an updateDocument function? but then
 * I wouldn't really be abstracting the database away, just the imports
 * second note: I already created that, called 'writeDocument'. I like this better.
 * third note: this is only one field at a time. By making them lists could do however many.
 * @param {*} 
 * @returns 
 */
export async function updateMeasure(measureId, changesObject) {
  const db = getFirestore();
  const measureDoc = doc(db, 'measures', measureId);
  try {
    await setDoc(measureDoc, changesObject, { merge: true });
    console.log("Updated measure ", measureId, " with changes: ", changesObject);
  } catch (error) {
    console.error(error);
  }
}

/**
 * create a new firebase user, but do not handle redirecting from there,
 * just set up their database, profile, and send verification email
 * @returns {FirebaseUser} I think
 */
export async function createNewFirebaseUser(nickname, state, county, city, email, password, termsID, referredBy, redirectLink)
{
  // user may leave whitespace
  nickname = nickname.trim();
  city = city.trim();
  // this should be checked with a reg exp?
  email = email.trim();
  console.log("Signing " + nickname + " up now.");
 
  const db = getFirestore();
  const auth = getAuth();

  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    await updateProfile(user, { displayName: nickname });
    await sendFirebaseEmailVerification(redirectLink);

    //batch writes to avoid inconsistency
    const batch = writeBatch(db);
    //write the data to the users' public....
    const userDoc = doc(db, 'users', user.uid);
    const newUserDocumentData = {
      Nickname: nickname,
      City: city,
      County: county,
      State: state
    };
    //(only set the upline if a referral id was provided)
    if (referredBy) newUserDocumentData.Upline = referredBy;
    batch.set(userDoc, newUserDocumentData);

    //...and private collections
    const userPrivateDoc = doc(db, 'users_private', user.uid);
    batch.set(userPrivateDoc, {
      City: city,
      County: county,
      State: state,
      Email: email,
      TermsID: termsID,
      signUpTimestamp: timestamp()
    });

    await batch.commit()
      .catch((error) => {
        console.error(error);
        throw new Error(error);
      });

    return user;
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * getPerson
 * retrieves the user's public information
 * @param {*} userID  
 * @returns 
 */
export async function getPerson(userID)
{
  const db = getFirestore();
  const userRef = doc(db, 'users', userID);

  const userInfoSnapshot = await getDoc(userRef)
    .catch((error) => {
      console.error(error);
      return;
    });

  if (userInfoSnapshot.exists()) {
    return userInfoSnapshot.data();
  } else {
    console.log("No user found for " + userID);
    return undefined;
  }
}

/**
 * listenToPerson
 * Listen to a person. Should I write this to just do the current
 * user?
 * @param {*} 
 * @returns 
 * note: removing because listenToDocument should work. Still might be a good idea.
 */
/*
export async function listenToPerson(userID) {
  const db = getFirestore();
  const userRef = doc(db, 'users', userID);

  const unsubscribe = userRef.onSnapshot((doc) => {
    if (doc.exists()) {

    } else {
      console.log("No such user: " + userID);
    }
  })
}*/

/**
 * followMeasure
 * @param {string} measureID 
 * @returns 
 */
export async function followMeasure(measureID)
{
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;
  const batch = writeBatch(db);

  const userRef = doc(db, 'users', user.uid);//, 'following', measureID);
  const measureFollowRef = doc(db, 'petitions', measureID, 'followers', user.uid);
  // will below timestamps be in accordance? Basically
  batch.set(userRef, { following: arrayUnion(measureID) }, { merge: true });
  batch.set(measureFollowRef, { timestamp: timestamp() });
  // the part that is missing is updating follower count on the measure itself; this
  // will still be handled by a function I think, but that may make for a weird delay
  // note: why handle with a function? So that users cannot change the follower count manually.

  await batch.commit();
}

/**
 * unfollowMeasure
 * @param {string} measureID 
 * @returns 
 */
export async function unfollowMeasure(measureID)
{
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;
  const batch = writeBatch(db);

  const userRef = doc(db, 'users', user.uid);
  const measureFollowRef = doc(db, 'petitions', measureID, 'followers', user.uid);
  batch.set(userRef, { following: arrayRemove(measureID) }, { merge: true });
  batch.delete(measureFollowRef);

  await batch.commit();
}

/**
 * getFileByPath
 * @param {string} path to the document
 * @param {string} name to give the document (should include extension e.g. .pdf)
 * 
 */
export async function getFileByPath(path, name) {
  const storage = getStorage();
  const storageRef = ref(storage, path);
  const blob = await getBlob(storageRef);
  const file = new File([blob], String(name));
  return file;
}

/**
 * getFilesByPathAndIds
 * @param {string} path 
 * @param {string Array} ids 
 * @returns {File Array}
 */
export async function getFilesByPathAndIds(path, ids) {
  const storage = getStorage();

  //fetch all the blobs in parallel, and turn them into files
  try {
    //create an array of promises for the stored files
    //this array of fulfillment values should be in the same order as ids
    const blobs = await Promise.all(
      ids.map((id) => {
        //add a trailing slash if missing from path
        if (path[-1] !== "/") path += "/";
        const storageRef = ref(storage, path + id);
        //getBlob will return a promise that resolves with the blob
        return (getBlob(storageRef));
      })
    );
    const files = [];
    for (let i = 0; i<blobs.length; i++) {
      //create the file with the name 'id.pdf'
      //or actually, don't specify file type, that way it can be specified
      //manually.
      files.push(new File([blobs[i]], String(ids[0])/*+'.pdf'*/));
    }
    return files;
  } catch (error) {
    //console.error(error);
    throw new Error(error);
  }
}

/**
 * getFullTextFile
 * @param measureID
 * @param pageSize
 * Searches Google storage for the full text of appropriate
 * measure and size
 */
export async function getFullTextFile(measureID, pageSize)
{
  const storage = getStorage();
  const storageRef = ref(storage, 'measureText/' + pageSize + '/' + measureID);

  //I believe errors from this will propagate?
  const blob = await getBlob(storageRef);
  const file = new File([blob], String(measureID)+'.pdf');
  return file;
}

/**
 * uploadFullText
 * takes the pdf file and its associated data and logs this to
 * the cloud storage bucket, and resolves with metadata from server
 * @param {*} 
 * @returns metadata object that was returned from server
 */
export async function uploadFullText(file, measureID, authorID, size)
{
  const storage = getStorage();
  const storageRef = ref(storage, 'measureText/' + size + '/' + measureID);
  const metadata = { customMetadata: { 'author': authorID } };

  //I think this should work...
  let snapshot;

  try {
    snapshot = await uploadBytes(storageRef, file, metadata);
    //snapshot = snapshot.timeCreated;
  } catch (error) {
    console.error(error);
    snapshot = false;
  }

  return snapshot;
}

/**
 * uploadMeasureInfo
 * takes all the data supplied by the proponent and uploads it
 * to the database as a proponent request that will be evaluated
 * before committing to actual database
 * note: why? So that proponents can't directly edit things like
 * their status on rBallot, and we can flag updates for review,
 * and possibly keep track of past versions or something
 */
export async function uploadMeasureInfo(measure, userID)
{
  //below will eventually live outside of this
  const db = getFirestore();
  const requestDoc = collection(db, "requests", userID, "organizations", measure.id, "measureEdits");

  try {
    const result = await addDoc(requestDoc, {
      ...measure,
      status: "new",
      request_timestamp: timestamp()
    });
    return result; //what will this be?
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * addUserAddress
 * This function is used to add a user address to users_private.
 * @param {Object} addressInfo
 * @returns success or error
 */
export async function addUserAddress(addressInfo) {
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;
  const userDoc = doc(db, 'users_private', user.uid);

  try {
    await setDoc(userDoc, addressInfo, {merge: true});
    console.log("User's private info recorded.");
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
}

/**
 * getUsersPrivateInfo
 * This function is used to get the user's private info to prefill forms.
 */
export async function getUsersPrivateInfo() {
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;
  const userDoc = doc(db, 'users_private', user.uid);

  try {
    const result = await getDoc(userDoc);
    return result.data();
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
}

/**
 * logMailingRequest
 * log a request to receive a signature sheet in the mail from proponent
 * @param {string} measureID
 * @returns 
 */
export async function logMailingRequest(measureID, requestInfo) {
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;
  //const measureRequestsDoc = doc(db, 'petitions', measureID, "mailing_requests", user.uid);
  const measureRequestsDoc = doc(collection(db, 'petitions', measureID, 'sections', 'mailing_requests', user.uid));
  const userDoc = doc(db, 'users', user.uid);
  const batch = writeBatch(db);

  //the measure's requests folder will hold the full request information,
  //while the user will just have an array of what they have signed in their document
  batch.set(measureRequestsDoc, {
    requested_timestamp: timestamp(),
    //using new rather than requested because that's how other requests work? hmm
    status: 'new',
    ...requestInfo,
    id: measureRequestsDoc.id
  });

  //user will hold information about all the different petitions they have signed
  batch.set(userDoc, {
    //I am not sure, but from the documentation I believe merge=true will also
    //apply to nested data, so this should not overwrite other things in requestedObj
    /*requested: arrayUnion(measureID),
    requestedObj: {
      [measureID]: {
        //when updates come in, they will change status, and add in e.g. mailed_timestamp
        status: 'requested',
        requested_timestamp: timestamp()
      }
    }*/
    petitionSections: {
      //count: FieldValue.increment(1),
      measures: arrayUnion(measureID),
      requestedMeasures: arrayUnion(measureID),
      [measureID]: arrayUnion(measureRequestsDoc.id), //keep track of the petitions for each measure
      [measureRequestsDoc.id]: { //but make the petition itself a first class property
        service: 'mailing',
        measure: measureID,
        status: 'requested',
        requested_timestamp: timestamp(),
        signatures: requestInfo.Signatures
      },
    }
  }, {merge: true});

  await batch.commit();
}

/**
 * logDownloadPetition
 * log that a petition is being downloaded to circulate
 * @param {string} measureID
 * @param {Object} info is the relevant entry from CurrentPetitionDownloads (should include id as a field)
 * @returns 
 */
export async function logDownloadPetition(measureID, info) {
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;
  const measureDownloadsDoc = doc(collection(db, 'petitions', measureID, 'sections', 'downloads', user.uid));
  const userDoc = doc(db, 'users', user.uid);
  const batch = writeBatch(db);
  //these cannot be uploaded to firestore, so delete if present
  delete info.File;

  try {
    batch.set(measureDownloadsDoc, {
      downloaded_timestamp: timestamp(),
      //using new rather than downloaded because that's how other requests work? hmm
      status: 'new',
      ...info
    });
    //user will hold information about all the different petitions they have signed
    batch.set(userDoc, {
      petitionSections: {
        //count: FieldValue.increment(1),
        measures: arrayUnion(measureID),
        downloadedMeasures: arrayUnion(measureID),
        [measureID]: arrayUnion(measureDownloadsDoc.id), //keep track of the petitions for each measure
        [measureDownloadsDoc.id]: { //but make the petition itself a first class property
          service: 'downloads',
          measure: measureID,
          status: 'downloaded',
          download: info, //which will be the entry from CurrentPetitionDownloads
          downloaded_timestamp: timestamp(),
          signatures: info.signatures //I have sort of randomly decided that user info will not be capitalized, while other info will...
        },
      }
    }, {merge: true});

    await batch.commit();
  } catch (error) {
    console.error(error);
  }
}

/**
 * updatePetitionInProgress
 * @param {String} requestType will be mailing_requests or downloads (note: this should just be the same as 'services', which says 'mailing' or 'downloads')
 * needs to overwrite user document field: petitionSections[id].status and petitionSections.signatures/signaturesPossible/signaturesGathered?
 * needs to overwrite measure petition request field: status, status_timestamp, signaturesGathered?
 * @returns 
 */
export async function updatePetitionInProgress(id, update, requestType) {
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;
  const measurePetitionDoc = doc(db, 'petitions', update.measure, 'sections', requestType, user.uid, id);
  const userDoc = doc(db, 'users', user.uid);
  //below are necessary so that we 
  const timestampName = update.status + "_timestamp"; //eg requested_timestamp or... mailed in_timestamp? hmm
  
  //I have to manually modify this because I did a terrible job keeping consistent naming
  let sectionType;
  if (requestType === "mailing_requests") sectionType = "requested";
  if (requestType === "downloads") sectionType = "downloaded";

  //can i use update to add a new field or solely to modify existing fields?
  const batch = writeBatch(db);
  batch.update(measurePetitionDoc, {
    status: update.status,
    signaturesGathered: update.signaturesGathered,
    [timestampName]: update.timestamp,
  });
  batch.update(userDoc, {
    ["petitionSections."+ id + ".status"]: update.status,
    ["petitionSections."+ id + ".signaturesGathered"]: update.signaturesGathered,
    ["petitionSections."+ id + "." + timestampName]: update.timestamp,
    //dude... what am I doing bro
    "signatures.total": increment(update.signaturesGathered),
    "signatures.totalProspective": increment(-update.signatures),
    ["signatures.self." + sectionType]: increment(update.signaturesGathered),
    ["signatures.self." + sectionType + "Prospective"]: increment(-update.signatures),
    "signatures.self.total": increment(update.signaturesGathered),
    "signatures.self.totalProspective": increment(-update.signatures),
    ["signatures.self." + update.measure + ".total"]: increment(update.signaturesGathered),
    ["signatures.self." + update.measure + ".totalProspective"]: increment(-update.signatures),
    ["signatures.self." + update.measure + "." + sectionType]: increment(update.signaturesGathered),
    ["signatures.self." + update.measure + "." + sectionType + "Prospective"]: increment(-update.signatures),
  });

  /**
   * Also need to affect user's signatures. Petition's must be handled with cloud function.
   * NOTE: x is signatures gathered, y is signatures possible
   * For user, need to modify:
   * signatures: {
   *  total: +x
   *  totalProspective: -y
   *  self: {
   *    requested/downloaded: +x
   *    requestedProspective/downloadedProspective: -y
   *    total: +x
   *    totalProspective: -y
   *    [measureID]: {
   *      requested/downloaded: +x
   *      requestedProspective/downloadedProspective: -y
   *      total: +x
   *      totalProspective: -y
   *    }
   *  }
   * }
   */

  try {
    await batch.commit();
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * sendFirebasePhoneNumberVerification
 * This function will take a string of the phone number, as well as a recaptcha
 * verifying (supposedly) that they are human, and use them to send a verification
 * text to the number through firebase. 
 * It will return the verificationId passed back by Firebase, allowing this
 * to be paired later with the user's input for verification code for full verification.
 * @param {string} phoneNumber //E.164: [+][country code][area code][subscriber number]
 * @param {string} recaptcha //recaptcha on the button that submits this form.
 * @returns {*}
 */
export async function sendFirebasePhoneNumberVerification(phoneNumber, recaptcha){
  console.log(recaptcha);
  const provider = new PhoneAuthProvider(auth);
  //strip phoneNumber of nondigits here, verify length
  //in E.164 format: [+][country code][area code][subscriber number]
  const verificationID = await provider.verifyPhoneNumber(phoneNumber, recaptcha)
    .catch((error) => {
      console.error(error);
    });

  return verificationID;
}

/**
 * createInvisibleRecaptcha
 * This function will create and return an invisible recaptcha
 * based on the element passed through.
 * @param {HTMLElement} element will be the element that will function
 * as the recaptcha. I guess they use the user's click as evidence of a human.
 */
export function createInvisibleRecaptcha(element){
  const auth = getAuth();
  const recaptcha = new RecaptchaVerifier(element, {size:'invisible'}, auth);
  return recaptcha;
}

/**
 * updateFirebasePhoneNumber
 * This function will be called using the code entered by the user,
 * and the verificationID sent by firebase. Together, these can be paired
 * to verify that the user in question has access to this phone number.
 * @param {*} verificationID 
 * @param {*} verificationCode 
 */
export async function updateFirebasePhoneNumber(verificationID, verificationCode){
  const auth = getAuth();
  //does this have to be the same PhoneAuthProvider?
  //const provider = new PhoneAuthProvider(auth);
  const phoneCredential = PhoneAuthProvider.credential(verificationID, verificationCode);
  const result = await updatePhoneNumber(auth.currentUser, phoneCredential)
    .catch((error) => console.error(error));

  return result;
}

/**
 * listenToDocument
 * This function will place a snapshot listener on a document.
 * It will return the unsubscribe function, so that this can
 * be used as the return value for useEffect. It will call a
 * setState function passed into it to update the information.
 * @param {Array} requestPath
 * @param {Function} setState
 * @returns {Function} 
 */
export function listenToDocument(requestPath, setState)
{
  const db = getFirestore();
  console.log(db, ...requestPath);
  const requestRef = doc(db, ...requestPath);

  const unsubscribe = onSnapshot(requestRef, function(doc){
    if (doc.exists()) {
      setState(doc.data());
      console.log("Snapshot fired with:");
      console.log(typeof doc.data() === Object ? Object.entries(doc.data()) : doc.data());
    } else {
      //will this be an issue that I'm not unsubscribing?
      //I mean, there's no document even... probably not
      setState(undefined);
      //throw new Error("Firebase document does not exist.");
    }
  });
  return unsubscribe;
}

/**
 * listenToQuery
 * Similar to above, but this time for a query
 * @param {Array} path 
 * @param {2D Array} queries
 * @param {2D Array} ordering
 * @param {Number} limit
 * @param {Function} setState
 * @returns {Function}
 */
export function listenToQuery(path, queries, ordering, _limit, setState)
{
  const db = getFirestore();
  const pathRef = collection(db, ...path);
  //this is convoluted, but might work?
  const wheres = !queries ? "" : queries.map((query) => where(...query));
  const orderBys = ordering.length === 0 ? [] : ordering.map((specifications) => orderBy(...specifications));

  const fullQuery = query(pathRef, ...wheres, ...orderBys, limit(_limit));

  const unsubscribe = onSnapshot(fullQuery, (querySnapshot) => {
    const results = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      data.id = doc.id;
      results.push(data);
    });

    //if limit is set to 1, don't return as an array
    if (_limit === 1) { 
      setState(results[0]);
    } else {
      setState(results);
    }
  });

  return unsubscribe;
}

/**
 * writeDocument
 * This function will be used by components that wish to write a specific
 * document at a specific point in time. First use case: For 
 * @param {Array} requestPath
 * @param {Object} data
 * @param {boolean} merge
 * @returns outcome of write 
 */
export async function writeDocument(requestPath, data, merge)
{
  const db = getFirestore();
  console.log(db, ...requestPath);
  const requestRef = doc(db, ...requestPath);

  const result = await setDoc(requestRef, data, {merge: merge})
    .catch((error) => {
      throw new Error(error);
    });

  return result;
}

/**
 * reloadUser
 * This function will be used to reload the user context
 * for the currently signed in user, in order to allow
 * programmatically checking to see the result of operations
 * to modify the details of the current user.
 * Now, I am having it return user, just to see the results.
 */
export async function reloadUser()
{
  const auth = getAuth();
  const user = auth.currentUser;
  console.log(user);
  //this should refresh the user, triggering new data,
  await user.reload();
  const newUser = auth.currentUser;
  console.log(newUser);
  return user;
}

/**
 * listenToPermissionsObject
 * This will listen to the currently signed in user's permissions object
 * @returns 
 */
export function listenToPermissionsObject(setPermissions) {
  const auth = getAuth();
  const user = auth.currentUser;
  const db = getFirestore();

  const permissionsRef = doc(db, 'users',  user.uid, 'permissions', 'permissionsObject');
  const unsubscribe = onSnapshot(permissionsRef, (doc) => {
    if (doc.exists()) {
      const permissions = doc.data();
      console.log(permissions);
      setPermissions(permissions);
    }
  });

  return unsubscribe;
}

/**
 * listenToMeasureCategoryRequests
 * Do I want this to set a listener on all of them? I suppose,
 * it won't actually be called unless they change anyway
 * @returns array of specified type of requests for measure that were submitted by signed in user
 * note: will be nontrivial to sort by timestamp, as there are two lists. But pretty easy, just put
 * the second list into the right spot in the former. Oh, actually, this is super easy, because if we
 * index in firebase they can both be retrieved sorted, and then you just use merge()
 */
export function listenToMeasureCategoryRequests(measureIDs, category, setRequests) {
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) return ("No user signed in.");

  //merge two arrays sorted by timestamp
  //wait... but then I'm putting it in an object.
  //should I return an array of the requestIds ordered by timestamp?
  //note: currently an infinite loop, probably
  /*
  const merge = (a, b) => {
    let c = [];
    let nextA = a.pop();
    let nextB = b.pop();
    
    console.log(a);
    console.log(b);

    if (nextA) console.log(Object.entries(nextA));
    if (nextB) console.log(Object.entries(nextB));
    //what does this mean
    if (nextA && nextB) console.log(nextA.request_timestamp.compareTo(nextB.request_timestamp));

    //merge until one is all that is left
    //also this is messing up because we pop the only item, so length goes to 0
    while (a.length !== 0 && b.length !== 0) {
      console.log(Object.entries(a));
      console.log(Object.entries(b));

      //what does this mean
      //console.log(a.request_timestamp.compareTo(b.request_timestamp));

      if (a.request_timestamp.compareTo(b.request_timestamp)){
        //a is more recent?
        c.push(nextA);
        nextA = a.pop();
      } else {
        //b is more recent?
        c.push(nextB);
        nextB = b.pop();
      }
    }

    //should be either or
    if (a.length !== 0) {
      c.concat(a);
    } else if (b.length !== 0) {
      c.concat(b);
    }

    //return an array of the indices
    return c.map((request) => {
      return request.id;
    });
  }*/

  //I should replace these with a function that returns correct formatting
  //const requestsRef = collection(db, 'requests', user.uid, 'organizations', measureID, category);
  //const requestsQuery = query(requestsRef, orderBy('request_timestamp'));
  const waitingRequestsRef = collection(db, 'requests', user.uid, 'waiting');
  const waitingRequestsQuery = query(waitingRequestsRef, 
    //where('measure', '==', measureID), 
    where('category', '==', category),
    orderBy('request_timestamp')
    );


  const results = {};

  /*const unsubscribe1 = onSnapshot(requestsQuery, (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      data.id = doc.id;
      results[doc.id] = data;
      console.log(data);
    });
    setRequests(results);
  });*/

  const unsubscribeWaiting = onSnapshot(waitingRequestsQuery, (querySnapshot) => {
    let data;
    querySnapshot.forEach((doc) => {
      data = doc.data();
      data.id = doc.id;
      results[doc.id] = data;
      console.log(data);
    });
    if (data) setRequests({...results, [data.id]: data});
  });

  const unsubscribes = [];
  for (let i = 0; i<measureIDs.length; i++) {
    console.log(measureIDs);
    console.log(measureIDs[i]);
    const ref = collection(db, 'requests', user.uid, 'organizations', measureIDs[i], category);
    let data;
    const unsubscribe = onSnapshot(ref, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        data = doc.data();
        data.id = doc.id;
        results[doc.id] = data;
        console.log(data);
      });
      if (data) setRequests({...results, [data.id]: data});
    });
    unsubscribes.push(unsubscribe);
  }

//trouble running merge here, as the data hasn't caught up. Might be difficult problem
//to fix

  const cleanUp = () => {
    unsubscribeWaiting();
    console.log("Unsubscribing from waiting requests.");
    for (let i = 0; i<unsubscribes.length; i++) {
      unsubscribes[i]();
      console.log("Unsubscribing from measure-specific request " + i);
    }
  }

  return cleanUp;
}

/**
 * logEmailVerified
 * This function will set the permissionsObj for the user to have emailVerified: true,
 * which should force a reset of the token to make any logged in version of this user
 * aware that they have been verified.
 */
export async function logEmailVerified() {
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;
  const permissionsRef = doc(db, 'users', user.uid, 'permissions', 'permissionsObject');
  console.log(user);
  await setDoc(permissionsRef, {
    emailVerified: true
  }, {merge:true});
}

/**
 * resubmitRequestsUponVerification
 * This function will take in a specific status, either 'phone-not-verified' or
 * 'email-not-verified', and then pull all requests matching this status from 'waiting',
 * then create a batch write that adds all of these to the correct final request destination
 * and deletes the requests that existed in waiting. Will require deletion privileges for waiting requests.
 * @returns 
 */
export async function resubmitRequestsUponVerification(status) {
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;

  const waitingRef = collection(db, 'requests', user.uid, 'waiting');
  const waitingQuery = query(waitingRef, where('status', '==', status));


  const requests = await getDocs(waitingQuery)
    .catch((error) => {
      console.error(error);
      throw new Error(error);
    });

  //are there any we need to process?
  if (requests.empty) return;

  //by batching all the writes and deletes, they will be completed
  //in one atomic operation. No loose ends.
  const batch = writeBatch(db);

  //to make sure we have the most up to date information
  //on user's email verification
  const token = await user.getIdTokenResult(true);
  console.log(token);

  requests.forEach(function(snapshot) {
    const request = snapshot.data();
    console.log(request);
    console.log(user);
    //if we have all necessary data in the request,
    if (request.measure && request.category) {
      //if the user is verified, send it to actual requests and remove from waiting
      if (user.emailVerified || token.claims.email_verified) {
        const newRef = doc(db, 'requests', user.uid, 'organizations',
          request.measure, request.category, snapshot.id);
        batch.set(newRef, {
          ...request,
          status: 'new',
          verification_timestamp: timestamp()
        });
        batch.delete(doc(db, 'requests', user.uid, 'waiting', snapshot.id));
        console.log("Fully submitting request " + snapshot.id);
      //or, if user is not verified, update status to 'email-not-verified' within waiting
      } else if (request.status === 'phone-not-verified' && !user.emailVerified) {
        batch.set(doc(db, 'requests', user.uid, 'waiting', snapshot.id), {
          status: 'email-not-verified',
          phone_verification_timestamp: timestamp()
        }, {merge: true});
        console.log("Updating status to 'email-not-verified' for request " + snapshot.id);
      } else {
        console.error("User's email is not verified. Aborting.");
      }
    //should we do anything if the request does not have enough information?
    } else {
      console.log("This request does not have enough information to be forwarded: ", snapshot.id);
    }
  });

  /*for (const snapshot in requests) {
    console.log(requests);
    console.log(snapshot);
  }*/

  await batch.commit()
    .catch((error) => {
      console.error(error);
      throw new Error(error);
    })

  return;
}

/**
 * verifyEmail
 * This function will take in the oobCode/actionCode from search params for verifying email and run it
 * @param {string} actionCode 
 */
export async function verifyEmail(code)
{
  const auth = getAuth();
  //console.log("Our API key is " + searchParams.get('apiKey'));
  try {
    //below will throw an error if the action code has already been applied
    await checkActionCode(auth, code);
    //here, actually apply the code
    await applyActionCode(auth, code);
    //Email address has been verified
    console.log("Email verified!");
    await resubmitRequestsUponVerification('email-not-verified');
    // this will ensure that if the verification takes place in another browser,
    // it will still propagate to the primary browser
    await logEmailVerified();
    //setVerified(true);
  } catch (error) {
    console.error(error);
    return;
  }
}

/**
 * recordCirculatorPacket
 * This function will fire when people create a new circulator packet on OrganizationPage.
 * It will record the key information on the packet they have created in the database,
 * ensuring this information is available to both the circulator and the managers of the measure.
 * Fields: signatures (#), page count, generation (pdf), timestamp, status, county, measure
 * @param {*} 
 * @returns 
 */
/*export async function recordCirculatorPacket(measureID, measure, county, signatures, pages, pageSize)
{
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw new Error("No user signed in, cannot record circulator packet.");

  //retrieve the generation of the full text used
  const storage = getStorage();
  const storageRef = ref(storage, 'measureText/' + pageSize + '/' + measureID);
  const metadata = await getMetadata(storageRef);

  const record = {
    signatures: signatures,
    pages: pages,
    status: 'circulating',
    user: user.uid,
    county: county,
    measure: measureID,
    deadline: measure.Deadline,
    pageSize: pageSize,
    generation: metadata.generation,
    timestamp: timestamp()
    /*
    also include timestamps for when key fields,
    and less key fields, were updated for measure.
    This will be recorded via EditMeasure and handleMeasureEdit.
    *//*
  }


  //here I'm thinking a few things. One is that it might be clearer for people
  //to call them 'circulator packets' vs 'petition sections', though perhaps
  //standardizing on the legal term is not a bad idea.
  //Second, that both people and proponents would want to easily fetch and sort
  //through all packets at once. Third, in order to ensure correctness, I can
  //use security rules that mandate the userID field matches the userID of the request.auth
  //note: decided to stick with sections for both, since they can't see it anyway
  const userPacketsRef = doc(db, 'users', user.uid, 'sections');
  const measureSectionsRef = collection(db, 'petitions', measureID, 'sections');

}*/

/**
 * signProp
 * This function will replace the old signature format that would store the ids
 * as an array. In this version, each user and petition will have a collection
 * "signatures" which will contain documents for each petition signed with info
 * on the identity of the petition/user as well as timestamps for each step of the process
 * @param {*} proposition: object containing proposition info
 * @param {*} user: object containing user's info
 * @param {function} setMessage: sets a message on the page
 * @param {function} setDisabledStatus: handles disabled status of button
 * could have a parameter that would pass in the correct className for styling
 * NOTE: needs to check personInfo here and not sign if it's wrong
 */
export async function signProp(proposition, user, personInfo, setMessage, setDisabledStatus)
{
  if (!user || !proposition)
  {
    console.log("Error: user is " + user + " and proposition is " + proposition);
    return "error";
  }

  console.log(proposition.deadline);
  console.log(now());
  //if this petition has already passed its deadline
  if (now() > proposition.deadline)
  {
    console.log("Error: petition is past its deadline of " + proposition.deadline);
    return "error";
  }

  const propRef = db.collection("petitions").doc(proposition.id);
  const propSignaturesRef = propRef.collection("signatures");
  const userSignaturesRef = db.collection("users").doc(user.id).collection("signatures");

  userSignaturesRef.doc(proposition.id).get().then(async function(doc)
  {
      console.log(user);
      console.log(proposition);
      console.log(personInfo);
      //if there is a document, and it has been signed (not just hidden)
      //then do not sign again
      if (doc.exists && doc.data().dateSigned)
      {
          if (setMessage) setMessage("You signed this petition already, on " + doc.data().dateSigned.toDate());
          console.log("Petition already signed.");
          return;
      //if the user is not a resident of the correct jurisdiction,
      //then do not sign
      //this should be below 
      //} else if (doc.data().jurisdictionName !== personInfo[doc.data().jurisdictionType]) {

      } else {
          //increment proposition's signature counter
          await propRef.get().then(function(doc)
          {
            //if the name of the jurisdiction for the prop does not match that type of
            //jurisdiction in the user personInfo passed in to signProp... then don't sign
            if (doc.data().JurisdictionName !== personInfo[doc.data().JurisdictionType])
            {
              console.log("Error in signing. Residency mismatch: Proposition "
                          + proposition.id + " has personInfo of " + doc.data().JurisdictionType +
                            ": " + doc.data().JurisdictionName + " while user has personInfo of " + 
                            personInfo[doc.data().JurisdictionType]
                            )
              return;
            } else {
              console.log("Residency matches: Proposition "
                          + proposition.id + " has personInfo of " + doc.data().JurisdictionType +
                          ": " + doc.data().JurisdictionName + " while user has personInfo of " + 
                          personInfo[doc.data().JurisdictionType]
                )
            }

            let newSigCount;
            if (doc.exists && doc.data().Signatures)
            {
                newSigCount = doc.data().Signatures+1;
            } else {
                newSigCount = 1;
            }
            console.log("Incrementing count from " + (doc.exists ? doc.data().Signatures : "0") + " to " + newSigCount);
            propRef.set({Signatures: newSigCount}, {merge: true});
          })

          //create a record in the proposition's signatures collection
          propSignaturesRef.doc(user.id).set({
            userID: user.id,
            //do we even need their name at all?
            displayName: user.name,
            //county added in to make it possible to figure out
            //signatures by county directly from prop. Important
            //for California, possibly others
            county: personInfo.County,
            dateSigned: timestamp(),
            dateOrdered: null,
            dateMailed: null,
            dateReceived: null,
            dateSentToProponents: null
          }).then(() => console.log("Proposition " + proposition.id + " has recorded being signed by user " + user.id) );

          //create a record in the user's signatures collection
          userSignaturesRef.doc(proposition.id).set({
            petitionID: proposition.id,
            title: proposition.title,
            summary: proposition.summary,
            fiscalImpact: proposition.fiscalImpact,
            county: personInfo.County,
            dateSigned: timestamp(),
            dateOrdered: null,
            dateMailed: null,
            dateReceived: null,
            dateSentToProponents: null,
            hiddenByUser: false
          }).then(() => console.log("User " + user.id + " has recorded signing petition " + proposition.id) );

      }
  })

  //this is to tell the user they signed it
  if (setMessage) setMessage("Petition added to cart!\n");
  if (setDisabledStatus) setDisabledStatus(true);
  return;
}

//sets the specified user to hide/unhide the specified proposition
//update 8/21/23: Will now change hiding from using the signature document,
//to being an array present on the user's document
//note: added async. Still should work I believe.
export async function setHide(measureID, userID, setting) {
  if (!measureID || !userID){
    console.log("Error: propositionID is " + measureID + " and userID is " + userID);
    return;
  }

  const db = getFirestore();

  //const signatureRef = db.collection("users").doc(userID).collection("signatures").doc(propositionID);
  const signatureRef = doc(db, 'users', userID);

  /*signatureRef.set({hiddenByUser: setting}, {merge: true}).then(() => {
    console.log("Set user " + userID + " to " + (setting ? "hide" : "unhide") + " proposition " + propositionID);
    return;
  });
  */
  console.log("Setting user " + userID + " to " + (setting ? "hide" : "unhide") + " measure " + measureID);

  //we are hiding
  if (setting) {
    await setDoc(signatureRef, {
      hiddenMeasures: arrayUnion(measureID)
    }, {merge: true})
  //we are unhiding
  } else {
    await setDoc(signatureRef, {
      hiddenMeasures: arrayRemove(measureID)
    }, {merge: true})
  }

  return;

}

/**
 * unsignProp
 * will need to modify disabled status to make this work right
 */
export async function unsignProp(proposition, user, setMessage, setDisabledStatus)
{
  if (!user || !proposition)
  {
    console.log("Error: user is " + user + " and proposition is " + proposition);
    return "error";
  }

  const propRef = db.collection("petitions").doc(proposition.id);
  const propSignaturesRef = propRef.collection("signatures");
  const userSignaturesRef = db.collection("users").doc(user.id).collection("signatures");

  userSignaturesRef.doc(proposition.id).get().then(async function(doc)
  {
      console.log(user);
      console.log(proposition);
      //if there is a document, and it has been signed (not just hidden)
      //then do not sign again
      if (!doc.exists || !doc.data().dateSigned)
      {
          if (setMessage) setMessage("You have yet to sign this proposition. Cannot unsign.");
          console.log("Trying to unsign " + proposition.id + ", but petition not signed yet.");
          return;
      //if the user is not a resident of the correct jurisdiction,
      //then do not sign
      //this should be below 
      //} else if (doc.data().jurisdictionName !== personInfo[doc.data().jurisdictionType]) {

      } else if (doc.exists && doc.data().dateOrdered) {
        if (setMessage) setMessage("Cannot unsign a petition that you already ordered to be delivered. You ordered " +
                                    proposition.id + " on " + doc.data().dateOrdered);
        console.log("Trying to unsign " + proposition.id + ", which has already been ordered for delivery on " + doc.data().dateOrdered);
        return;
      } else {
        //decrement signature count
        await propRef.get().then(function(doc)
        {
          let newSigCount;
          if (doc.exists && doc.data().Signatures)
          {
              newSigCount = doc.data().Signatures-1;
          } else {
              console.log("No signature count found. Not unsigning.");
              //if no sig count, just don't mess with it I think
              return;
              //newSigCount = 0;
          }
          console.log("Decrementing count from " + (doc.exists ? doc.data().Signatures : "0") + " to " + newSigCount);
          propRef.set({Signatures: newSigCount}, {merge: true});
        })

        //delete the record in the proposition's signatures collection
        propSignaturesRef.doc(user.id).delete()
        .then(() => console.log("Proposition " + proposition.id + " has been unsigned by user " + user.id) );

        //delete the record in the user's signatures collection
        userSignaturesRef.doc(proposition.id).delete()
        .then(() => console.log("User " + user.id + " has recorded unsigning petition " + proposition.id) );
      }
  })

    //this is to tell the user they unsigned it
    if (setMessage) setMessage("Petition unsigned.\n");
    if (setDisabledStatus) setDisabledStatus(false);
    return;
}

//is this necessary? A few that weren't in here were being exported seemingly
//successfully before I just added them in.
const exportedFirebase = {
  db, 
  auth,
  sendFirebaseEmailVerification,
  getMeasure,
  getFullTextFile,
  uploadFullText,
  uploadMeasureInfo, 
  timestamp, 
  sendFirebasePhoneNumberVerification,
  createInvisibleRecaptcha,
  updateFirebasePhoneNumber,
  reloadUser,
  listenToDocument,
  listenToMeasureCategoryRequests,
  signProp, 
  unsignProp, 
  setHide
};

export default exportedFirebase;



  // authUI is our reference to an instance of firebaseUI,
// which is used to sign in users to auth
/*
export var authUI = new firebaseui.auth.AuthUI(auth);
// uiConfig will be used to configure the firebaseUI
export var uiConfig = {
  callbacks: {

  },
  signInSuccessURL: '/UserFeedPage',
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ]
} 
*/